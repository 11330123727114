import React, { useContext, useEffect, useState } from 'react';
import scrollTop from '../helpers/scrollTop';
import displayINRCurrency from '../helpers/displayCurrency';
import Context from '../context';
import addToCart from '../helpers/addToCart';
import { Link, useNavigate } from 'react-router-dom';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const VerticalCard = ({ loading, data = [] }) => {
    const loadingList = new Array(13).fill(null);
    const { fetchUserAddToCart } = useContext(Context);

    const navigate = useNavigate();

    // Helper function to encode product_id
    const encodeProductId = (id) => {
        if (!id) return '';
        return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
    };

    const handleAddToCart = async (e, id, unit_id, name) => {
        const response = await addToCart(e, id, unit_id);
        if (response.status) {
            toast.success(`${name} added to cart`)
        }
        else {
            navigate('/login')
            toast.error(response.message)
        }
        fetchUserAddToCart();
    };

    const renderStars = (rating) => {
        const stars = [];
        const fullStars = Math.floor(rating);

        for (let i = 0; i < fullStars; i++) {
            stars.push(<IoStar key={i} />);
        }
        const remainingStars = 5 - stars.length;
        for (let i = 0; i < remainingStars; i++) {
            stars.push(<IoStarOutline key={stars.length + i} />);
        }

        return stars;
    };

    return (
        <div>
            {
                !loading && data.length === 0 && (
                    <div className='flex justify-center'>
                        <p className='font-medium text-slate-800 text-lg my-2'>
                            No Products Found
                        </p>
                    </div>
                )
            }
            <div className='grid grid-cols-[repeat(auto-fit,minmax(160px,170px))] md:grid-cols-[repeat(auto-fit,minmax(260px,290px))] justify-center md:justify-start md:gap-5 overflow-x-scroll scrollbar-none transition-all'>
                {loading ? (
                    loadingList.map((_, index) => (
                        <div key={index} className='w-full min-w-[160px]  md:min-w-[300px] max-w-[160px] md:max-w-[300px]  bg-white rounded-sm my-1 md:m-4 border'>
                            <div className='bg-slate-200 h-48 p-4 max-w-[160px] md:max-w-[300px] flex justify-center items-center animate-pulse'></div>
                            <div className='p-4 grid gap-3'>
                                <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                                <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200  py-2'></p>
                                <div className='flex gap-3'>
                                    <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
                                    <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full  py-2'></p>
                                </div>
                                <button className='text-sm  text-white px-3  rounded-full bg-slate-200  py-2 animate-pulse'></button>
                            </div>
                        </div>
                    ))
                ) : (
                    data.map((product, index) => {

                        return (
                            <Link key={index} to={`/product/${encodeProductId(product?.product_id)}`} className='w-full min-w-[160px]  md:min-w-[300px] max-w-[160px] md:max-w-[300px]  bg-white rounded-sm my-1 md:m-4 border' onClick={scrollTop}>
                                <div className='relative bg-slate-100 h-32 md:h-48 p-4 max-w-[160px] md:max-w-[320px] flex justify-center items-center'>
                                    <img
                                        src={`${SummaryApi.productImage.url}${product?.product_img1}`}
                                        className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
                                        alt='Product'
                                    />
                                    {product?.brand_name && (
                                        <h2 className='hidden md:block absolute top-4 left-2 text-[10px] bg-red-200 text-red-600 px-2 py-1 rounded-full w-fit'>
                                            {product?.brand_name}
                                        </h2>
                                    )}
                                </div>

                                <div className='p-4 md:p-4 grid gap-1'>
                                    <h2 className='text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.product_name}</h2>
                                    <p className='text-sm md:text-lg capitalize text-slate-500'>{product?.category_name}</p>
                                    <div className='text-orange-500 text-sm md:text-lg flex items-center gap-1'>
                                        {renderStars(product?.rating)}
                                        {/* <p className='text-slate-600'>({product?.price - Math.floor(Math.random() * 1000)})</p> */}
                                    </div>
                                    <div className='flex gap-3'>
                                        <p className='text-black text-sm md:text-lg font-medium'>{displayINRCurrency(product?.after_discount_price)}</p>
                                        <p className='text-slate-500 text-sm md:text-lg line-through'>{displayINRCurrency(product?.price)}</p>
                                        <p className='hidden md:flex text-green-700 text-sm md:text-lg font-medium'>
                                            {Math.round(((product?.price - product?.after_discount_price) / product?.price) * 100)}% off
                                        </p>
                                    </div>
                                    <button className='text-sm h-10 md:h-10 bg-red-600 hover:bg-red-700 text-white px-3 py-0.5 rounded-lg' onClick={(e) => handleAddToCart(e, product?.product_id, product?.product_unit_id, product?.product_name,)}>
                                        Add to Cart
                                    </button>
                                </div>
                            </Link>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default VerticalCard;
