import './App.css';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import SummaryApi from './common';
import Context from './context';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom'; // Import useLocation to get current route

function App() {
  const dispatch = useDispatch();
  const [cartProductCount, setCartProductCount] = useState(0);
  const location = useLocation(); // Get the current route

  const fetchUserAddToCart = async () => {
    const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA');
    const customerId = encodedCustomerId == null
      ? null
      : decodeURIComponent(atob(Cookies.get('M8V5R1JQ3K0PWT6Z9LXA')));
    const dataResponse = await fetch(SummaryApi.totalQuantity.url, {
      method: SummaryApi.totalQuantity.method,
      headers: {
        'Authorization': 'Bearer oakyaecom_no_units_916',
        "content-type": 'application/json'
      },
      body: JSON.stringify({ "customerid": customerId })
    });

    const dataApi = await dataResponse.json();
    setCartProductCount(dataApi?.totalQuantity);
  }

  useEffect(() => {
    fetchUserAddToCart();
  }, []);

  const isAdminRoute = location.pathname.startsWith('/admin-panel'); // Check if current route is admin-related

  return (
    <>
      <Context.Provider value={{
        cartProductCount,
        fetchUserAddToCart
      }}>
        <ToastContainer position='top-center' />

        {!isAdminRoute && <Header />} {/* Conditionally render Header */}
        <main className='min-h-[calc(100vh-120px)] pt-0'>
          <Outlet />
        </main>
        {!isAdminRoute && <Footer />} {/* Conditionally render Footer */}
      </Context.Provider>
    </>
  );
}

export default App;
