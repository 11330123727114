import React, { useEffect, useState } from 'react';
import CategoryList from '../components/CategoryList';
import BannerProduct from '../components/BannerProduct';
import HorizontalCardProduct from '../components/HorizontalCardProduct';
import HomeProductCard from '../components/HomeProductCard';

const Home = () => {
  return (
    <div className="container mx-auto">
      <div className="text-center">
        <CategoryList />
        <BannerProduct />
      </div>
      
      <div>
        <HorizontalCardProduct heading={"Best Seller"} />
      </div>
      
      <div>
        <HomeProductCard />
      </div>
    </div>
  );
};

export default Home;
