import React, { useState, useEffect } from 'react';
import SummaryApi from '../common';
import Customer from '../assest/customer.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import displayINRCurrency from '../helpers/displayCurrency';

const EditOrder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { orderid } = location.state || {};
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState({});
    const [editedOrderStatus, setEditedOrderStatus] = useState('');
    const [initialStatus, setInitialStatus] = useState('');

    const encodeProductId = (id) => {
        if (!id) return '';
        return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
    };

    const orderStatusOptions = [
        'Order placed',
        'Processing',
        'Order confirmed',
        'Order in transit',
        'Delivered',
        'Cancelled'
    ];

    const forwardStatusOptions = {
        'Order placed': ['Processing', 'Cancelled'],
        'Processing': ['Order confirmed', 'Cancelled'],
        'Order confirmed': ['Order in transit', 'Cancelled'],
        'Order in transit': ['Delivered'],
        'Delivered': [],
        'Cancelled': []
    };

    useEffect(() => {
        fetchOrder();
    }, []);

    const fetchOrder = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(SummaryApi.orderDetailsByOrderID.url, {
                method: SummaryApi.orderDetailsByOrderID.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_no_units_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ orderid: orderid }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();

            if (responseData.status) {
                setOrderData(responseData.data);
                setEditedOrderStatus(responseData.data.order_status); // Update editedOrderStatus
                setInitialStatus(responseData.data.order_status); // Update initialStatus
            } else {
                toast.error('Failed to fetch order details');
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
            toast.error('Error fetching orders');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleOrderStatusChange = (e) => {
        const newStatus = e.target.value;
        if (forwardStatusOptions[editedOrderStatus]?.includes(newStatus)) {
            setEditedOrderStatus(newStatus);
        }
    };

    const handleSubmit = async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(SummaryApi.editOrderStatus.url, {
                method: SummaryApi.editOrderStatus.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_no_units_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderid: orderData.order_id,
                    orderstatus: editedOrderStatus,
                }),
            });

            if (response.ok) {
                toast.success('Order status updated successfully!');
                fetchOrder(); // Fetch updated order details after status update
            } else {
                toast.error('Failed to update order status. Please try again.');
            }
        } catch (error) {
            console.error('Error updating order status:', error);
            toast.error('An error occurred while updating the order status. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const formatDateTime = (dateString) => {
        if (dateString === '0000-00-00') {
            return 'Not yet delivered';
        }
        const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        return `${date.toLocaleDateString('en-US', optionsDate)} ${date.toLocaleTimeString('en-US', optionsTime)}`;
    };

    const navigateToAllOrders = () => {
        navigate('/admin-panel/all-orders');
    };

    const navigateToInvoice = () => {
        navigate(`/admin-panel/invoice/${encodeProductId(orderData.order_id)}`, { state: { order: orderData } });
    };
    const navigateToOrderHistory = () => {
        navigate(`/admin-panel/order-history`, { state: { customerID: orderData.customer_id } });
    }
    return (
        <div className="relative mx-auto">
            {/* Loading Mask */}
            {loading && (
                <div className='absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center'>
                    <div className='text-lg font-bold'>Loading...</div>
                </div>
            )}

            <div className="flex justify-end mb-2 space-x-2">
                <button
                    onClick={navigateToAllOrders}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-xs md:text-sm"
                >
                    All Orders
                </button>
                <button
                    onClick={navigateToInvoice}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-xs md:text-sm"
                >
                    View Invoice
                </button>
            </div>
            <div className="flex flex-col lg:flex-row lg:space-x-6">
                <div className="w-full lg:w-1/4 flex flex-col space-y-6 mb-6 lg:mb-0">
                    <div className="bg-white p-6 rounded-lg shadow-md border">
                        <div className="flex items-center space-x-4">
                            <img className="h-16 w-16 rounded-full" src={Customer} alt="Customer Avatar" />
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold text-gray-800">{orderData.customer_name}</h2>
                                <p className="text-xs md:text-sm text-gray-600">{orderData.email}</p>
                                <p className="text-xs md:text-sm text-gray-600">{orderData.mobile_number}</p>
                                <a onClick={navigateToOrderHistory} className="text-blue-600 text-xs md:text-sm">
                                    View Order History
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md border flex-1">
                        <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">Order Address:</h3>
                        <p className="text-xs md:text-sm text-gray-600">{orderData.order_address}</p>
                        <div className="mt-4 relative h-96">
                            <iframe
                                src={`https://www.google.com/maps?q=${orderData.latitude},${orderData.longitude}&z=15&output=embed`}
                                width="100%"
                                height="100%"
                                allowFullScreen
                                loading="lazy"
                                title="Order Address"
                                className="absolute inset-0"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="flex-1 lg:flex lg:flex-col lg:space-y-6">
                    <div className="bg-white p-6 rounded-lg shadow-md border mb-6 lg:mb-0 flex-1">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4 border-b">Order Details</h2>
                        <div className="gap-4">
                            <div className="mb-4">
                                <p className="flex justify-between text-xs md:text-base text-gray-700 my-3">
                                    <strong>Order Code:</strong> <span className="font-semibold">{orderData.order_code}</span>
                                </p>
                                <p className="flex justify-between text-xs md:text-base text-gray-700 my-3">
                                    <strong>Order Date:</strong> <span className="font-semibold">{formatDateTime(orderData.order_date_time)}</span>
                                </p>
                            </div>
                            <div className="p-4 border border-gray-200 rounded-lg bg-yellow-50">
                                <p className="flex justify-between text-xs md:text-base text-gray-700 my-3">
                                    <strong>Order Amount:</strong> <span className="font-semibold">{displayINRCurrency(orderData.order_amount)}</span>
                                </p>
                                <p className="flex justify-between text-xs md:text-base text-gray-700 my-3">
                                    <strong>Delivery Charges:</strong>
                                    <span className="font-semibold">{displayINRCurrency(orderData.delivery_charges)}</span>
                                </p>
                                <p className="flex justify-between text-xs md:text-base text-gray-700 my-3">
                                    <strong>GST Charges:</strong>
                                    <span className="font-semibold"> {displayINRCurrency(orderData.GST)}</span>
                                </p>
                                <p className="flex justify-between text-xs md:text-base text-gray-700 my-3">
                                    <strong>Other Charges:</strong>
                                    <span className="font-semibold"> {displayINRCurrency(orderData.other_charges)}</span>
                                </p>
                                <p className="flex justify-between text-base md:text-lg text-gray-700 my-3 border-t pt-2">
                                    <strong>Total Amount:</strong>
                                    <span className="font-semibold text-red-600">{displayINRCurrency(orderData.total_amount)}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md border flex-1">
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4 border-b">Delivery Details</h2>
                        <div className="gap-4">
                            <p className="flex justify-between text-xs md:text-base text-gray-700 my-3">
                                <strong>Delivery Date:</strong> <span className="font-semibold">{formatDateTime(orderData.delivery_date)}</span>
                            </p>
                            <div className="mt-4">
                                <label htmlFor="orderStatus" className="block text-xs md:text-sm font-medium text-gray-700">
                                    Order Status
                                </label>
                                <select
                                    id="orderStatus"
                                    className="mt-1 p-2 border border-gray-300 rounded-md w-full text-xs md:text-sm focus:outline-none focus:border-blue-500"
                                    value={editedOrderStatus}
                                    onChange={handleOrderStatusChange}
                                >
                                    {orderStatusOptions
                                        .filter(status => forwardStatusOptions[editedOrderStatus]?.includes(status) || editedOrderStatus === status)
                                        .map(status => (
                                            <option key={status} value={status}>{status}</option>
                                        ))}
                                </select>
                            </div>
                            <div className="flex justify-end mt-4">
                                {initialStatus !== 'Delivered' && initialStatus !== 'Cancelled' && (
                                    <button
                                        id="submitBtn"
                                        onClick={handleSubmit}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-xs md:text-sm"
                                    >
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md border mt-6">
                <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-800 border-b">Items List</h2>
                {orderData.order_items && orderData.order_items.length > 0 ? (
                    <div className="flex flex-col space-y-4">
                        {orderData.order_items.map((item) => (
                            <div
                                key={item.order_items_id}
                                className="flex flex-col md:flex-row items-start md:items-center p-4 bg-white rounded-lg shadow-md border relative"
                            >
                                <div className="flex items-center flex-1">
                                    <img
                                        src={SummaryApi.productImage.url + item.product_img}
                                        alt={item.product_name}
                                        className="h-24 w-24 object-contain mr-4"
                                    />
                                    <div className="flex flex-col">
                                        <h4 className="text-base md:text-lg font-semibold">{item.product_name}</h4>
                                        <p className="text-xs md:text-sm text-gray-600">{displayINRCurrency(item.price)} x {item.quantity}</p>
                                    </div>
                                </div>
                                <p className="text-base md:text-lg font-semibold absolute bottom-0 right-4 md:static md:bottom-auto md:right-auto">
                                    {displayINRCurrency(item.subtotal)}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-600 text-xs md:text-sm">No items found</p>
                )}
            </div>
        </div>
    );
};

export default EditOrder;
