// const backendDomain = "http://localhost/"
const backendDomain = "https://oakyaecom.oakya.tech"

const SummaryApi = {
    signUP : {
        url : `${backendDomain}/api/customer/create.php`,
        method : "post"
    },
    otpVerification : {
        url : `${backendDomain}/api/customer/retrieve.php`,
        method : "get"
    },
    getCustomerDetails : {
        url : `${backendDomain}/api/customer/retrieve.php`,
        method : "post"
    },
    uploadCategory : {
        url : `${backendDomain}/api/category/create.php`,
        method : 'post'
    },
    uploadSubCategory : {
        url : `${backendDomain}/api/subcategory/create.php`,
        method : 'post'
    },
    allCategory : {
        url : `${backendDomain}/api/category/retrieve.php`,
        method : 'get'
    },
    adminSubCategory : {
        url : `${backendDomain}/api/category/adminsubcategory.php`,
        method : 'get'
    },
    adminCategory : {
        url : `${backendDomain}/api/category/admincategory.php`,
        method : 'get'
    },
    allBrand : {
        url : `${backendDomain}/api/brand/retrieve.php`,
        method : 'get'
    },
    adminBrand : {
        url : `${backendDomain}/api/brand/adminbrand.php`,
        method : 'get'
    },
    allSubCategory : {
        url : `${backendDomain}/api/subcategory/retrieve.php`,
        method : 'get'
    },
    allSubCategoryByID:{
        url : `${backendDomain}/api/subcategory/retrieve.php`,
        method : 'post'
    },
    image:{
        url:`${backendDomain}/api/category/`
    },
    productImage : {
        url:`${backendDomain}/api/product/`
    },
    brandImage : {
        url:`${backendDomain}/api/brand/`
    },
    subCategoryImage : {
        url:`${backendDomain}/api/subcategory/`
    },
    updateCategory : {
        url : `${backendDomain}/api/category/update.php`,
        method  : 'post'
    },
    updateSubCategory : {
        url : `${backendDomain}/api/subcategory/update.php`,
        method  : 'post'
    },
    updateBrand : {
        url : `${backendDomain}/api/brand/update.php`,
        method  : 'post'
    },
    uploadProduct : {
        url : `${backendDomain}/api/product/create.php`,
        method : 'post'
    },
    uploadBrand : {
        url : `${backendDomain}/api/brand/create.php`,
        method : 'post'
    },
    uploadAddress : {
        url : `${backendDomain}/api/address/create.php`,
        method : 'post'
    },
    getAddress:{
        url : `${backendDomain}/api/address/retrieve.php`,
        method : 'post'
    },
    updateAddress:{
        url : `${backendDomain}/api/address/update.php`,
        method : 'post'
    },
    updateDefaultAddress:{
        url : `${backendDomain}/api/address/update.php`,
        method : 'put'
    },
    deleteAddress:{
        url : `${backendDomain}/api/address/delete.php`,
        method : 'delete'
    },
    verifyAdmin: {
        url : `${backendDomain}/api/admin/retrieve.php`,
        method : 'put'
    },
    verifyEmail: {
        url : `${backendDomain}/api/customer/userlogin.php`,
        method : 'post'
    },
    verifyOTP: {
        url : `${backendDomain}/api/customer/create.php`,
        method : 'put'
    },
    verifyOldPassword: {
        url : `${backendDomain}/api/admin/retrieve.php`,
        method : 'post'
    },
    updateNewPassword: {
        url : `${backendDomain}/api/admin/update.php`,
        method : 'post'
    },
    allUser : {
        url : `${backendDomain}/api/customer/retrieve.php`,
        method : 'get'
    },
    allProduct : {
        url : `${backendDomain}/api/product/retrieve.php`,
        method : 'get'
    },
    adminProduct : {
        url : `${backendDomain}/api/product/adminproduct.php`,
        method : 'get'
    },
    updateProduct : {
        url : `${backendDomain}/api/product/update.php`,
        method  : 'post'
    },
    productDetails : {
        url : `${backendDomain}/api/product/retrieve.php`,
        method : 'put'
    },
    addToCartProduct : {
        url : `${backendDomain}/api/cart/create.php`,
        method : 'post'
    },
    addToCartProductView : {
        url : `${backendDomain}/api/cart/retrieve.php`,
        method : 'post'
    },
    totalQuantity: {
        url : `${backendDomain}/api/cart/retrieve.php`,
        method : 'put'
    },
    updateCartProduct : {
        url : `${backendDomain}/api/cart/update.php`,
        method : 'post'
    },
    deleteCartProduct : {
        url : `${backendDomain}/api/cart/delete.php`,
        method : 'delete'
    },
    getProduct : {
        url : `${backendDomain}/api/product/retrieve.php`,
        method : 'put'
    },
    getBestSeller : {
        url : `${backendDomain}/api/product/bestseller.php`,
        method : 'post'
    },
    getHomeDisplay : {
        url : `${backendDomain}/api/product/homedisplay.php`,
        method : 'get'
    },
    orderDetails : {
        url : `${backendDomain}/api/orderdetails/create.php`,
        method : 'post'
    },
    orderDetailsByID:{
        url : `${backendDomain}/api/orderdetails/retrieve.php`,
        method : 'post'
    },
    orderDetailsByOrderID:{
        url : `${backendDomain}/api/orderdetails/retrieve.php`,
        method : 'put'
    },
    allOrder:{
        url : `${backendDomain}/api/orderdetails/retrieve.php`,
        method : 'get'
    },
    allOrderItems:{
        url : `${backendDomain}/api/orderitems/retrieve.php`,
        method : 'get'
    },
    searchProduct:{
        url : `${backendDomain}/api/product/search.php`,
        method : 'post'
    },
    categoryWiseProduct: {
        url : `${backendDomain}/api/product/retrieve.php`,
        method : 'post'
    },
    editOrderStatus: {
        url : `${backendDomain}/api/orderdetails/update.php`,
        method : 'post'
    },
    getBannerImages: {
        url : `${backendDomain}/api/banners/retrieve.php`,
        method : 'get'
    },
    editBannerImage: {
        url : `${backendDomain}/api/banners/update.php`,
        method : 'post'
    },
    bannerImage: {
        url : `${backendDomain}/api/banners/`
    },
    getAllBannerProduct: {
        url : `${backendDomain}/api/banner_product/retrieve.php`,
        method: 'post'
    },
    updateBanner: {
        url : `${backendDomain}/api/banner_product/update.php`,
        method: 'post'
    },
    getBannerProduct: {
        url : `${backendDomain}/api/product/banner_products.php`,
        method: 'post'
    },
    updateCategoryPositions: {
        url : `${backendDomain}/api/category/update.php`,
        method: 'put'
    },
}

export default SummaryApi