import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import Logo from '../assest/Ecom10.jpg';  // Ensure the path is correct
import displayINRCurrency from '../helpers/displayCurrency';

const Invoice = () => {
    const location = useLocation();
    const { order } = location.state || {};
    const navigate = useNavigate();
    console.log(order);
    if (!order) {
        return <p>No order data available.</p>;
    }

    // Function to handle printing
    const handlePrint = () => {
        const element = document.getElementById('invoice-content');
        const options = {
            margin: 0.5, // Reduce margins to fit content better
            filename: `Invoice_${order.order_code}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // Create a print-specific stylesheet
        const style = document.createElement('style');
        style.innerHTML = `
            @media print {
                .no-print { display: none; }
                @page { size: auto; margin: 0.5in; }
                .print-content { page-break-inside: avoid; }
                .text-center { text-align: center; }
            }
        `;
        document.head.appendChild(style);

        // Save the PDF
        html2pdf().from(element).set(options).toPdf().get('pdf').then(pdf => {
            // Apply print-specific styles and print the document
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
        });

        // Clean up the print-specific stylesheet
        setTimeout(() => {
            document.head.removeChild(style);
        }, 0);
    };

    // Function to handle downloading as PDF
    const handleDownload = () => {
        const element = document.getElementById('invoice-content');
        const options = {
            margin: 0.5, // Reduce margins to fit content better
            filename: `Invoice_${order.order_code}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().from(element).set(options).save();
    };


    const handleEdit = (orderid) => {
        navigate('/admin-panel/edit-order', { state: { orderid } });
      };
    return (
        <div>
            <div className="print:hidden flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-2 mt-6">
                <div>
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handlePrint}
                    >
                        Print
                    </button>
                    <button
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2"
                        onClick={handleDownload}
                    >
                        Download
                    </button>
                </div>
                <div>
                    <button
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded md:ml-[100px]"
                        onClick={() => handleEdit(order.order_id)}
                    >
                        Order details
                    </button>
                </div>
            </div>
            <div className="p-3 max-w-5xl mx-auto bg-white border my-6 border-gray-300 rounded-lg shadow-lg print-content" id="invoice-content">
                <div className="text-center mb-6">
                    {/* Logo and Firm Name Section */}
                    <div className="flex items-center justify-center">
                        <img
                            src={Logo}
                            alt="Firm Logo"
                            className="h-12 w-auto mb-2"  // Adjust the size of the logo
                        />
                        <h1 className="text-3xl font-bold mb-2">OAKYA SOLUTIONS</h1>
                    </div>
                    <h2 className="text-2xl sm:text-3xl font-bold mb-2">Invoice</h2>
                </div>

                <div className="mb-6 sm:mb-8 p-4 border border-gray-200 rounded-md">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <div className='flex justify-between border-b border-gray-300'>
                            <p className="font-semibold mb-1">Invoice No:</p>
                            <p>{order.order_code}</p>
                        </div>
                        <div className='flex justify-between border-b border-gray-300'>
                            <p className="font-semibold mb-1">Order ID:</p>
                            <p>{order.order_id}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <div className='flex justify-between border-b border-gray-300'>
                            <p className="font-semibold mb-1">Order Date:</p>
                            <p>{new Date(order.order_date_time).toLocaleDateString()}</p>
                        </div>
                        <div className='flex justify-between border-b border-gray-300'>
                            <p className="font-semibold mb-1">Invoice Date:</p>
                            <p>{new Date().toLocaleDateString()}</p>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
                    <div className="p-4 border border-gray-200 rounded-md">
                        <h3 className="text-lg font-semibold mb-4">Bill To:</h3>
                        <p>{order.customer_name}</p>
                        <p>{order.order_address}</p>
                        <p>{order.country}</p>
                    </div>
                    <div className="p-4 border border-gray-200 rounded-md">
                        <h3 className="text-lg font-semibold mb-4">Ship To:</h3>
                        <p>{order.customer_name}</p>
                        <p>{order.order_address}</p>
                        <p>{order.country}</p>
                    </div>
                </div>

                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-4">Product</h3>
                    <div className="overflow-x-auto">
                        <table className="w-full border border-gray-300 border-collapse mb-4">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="border border-gray-300 p-2 text-left">Product</th>
                                    <th className="border border-gray-300 p-2 text-left">QTY</th>
                                    <th className="border border-gray-300 p-2 text-left">Price</th>
                                    <th className="border border-gray-300 p-2 text-left">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.order_items.map(item => (
                                    <tr key={item.order_items_id}>
                                        <td className="border border-gray-300 p-2">
                                            {item.product_name}
                                            {item.warranty && <p className="text-xs text-gray-600">Warranty: {item.warranty}</p>}
                                        </td>
                                        <td className="border border-gray-300 p-2">{item.quantity}</td>
                                        <td className="border border-gray-300 p-2">{displayINRCurrency(item.price)}</td>
                                        <td className="border border-gray-300 p-2">{displayINRCurrency(item.subtotal)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="p-4 border border-gray-200 rounded-md">
                        <div className="flex flex-col sm:flex-row justify-between mb-2">
                            <p className="font-semibold">Order Amount:</p>
                            <p>{displayINRCurrency(order.order_amount)}</p>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between mb-2">
                            <p className="font-semibold">Delivery Charges:</p>
                            <p>{displayINRCurrency(order.delivery_charges || '0.00')}</p>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between mb-2">
                            <p className="font-semibold">GST Charges:</p>
                            <p>{displayINRCurrency(order.gst_charges || '0.00')}</p>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between mb-2">
                            <p className="font-semibold">Other Charges:</p>
                            <p>{displayINRCurrency(order.other_charges || '0.00')}</p>
                        </div>
                        <div className="flex flex-col sm:flex-row justify-between font-semibold">
                            <p>Total Amount:</p>
                            <p>{displayINRCurrency(order.total_amount)}</p>
                        </div>
                    </div>
                </div>

                <div className="text-center mb-6">
                    <p className="mb-4 text-lg font-semibold">Thank You!</p>
                    <p className="text-sm text-gray-600 mb-2">For Shopping with us</p>
                    <p className="text-sm text-gray-600 mb-2">
                        Returns Policy: We try to deliver perfectly each and every time. But in the off-chance that you need to return the item, please do so with the original Brand box/price tag, original packing and invoice without which it will be really difficult for us to act on your request. Please help us in helping you. Terms and conditions apply.
                    </p>
                    <p className="text-sm text-gray-600">Helpline: XXXX XXX XXXX</p>
                </div>
            </div>
        </div>
    );
};

export default Invoice;
