import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const Modal = ({ isOpen, onClose, onSave, address, setAddress, isEditing, fetchAddress }) => {
  const [mapPosition, setMapPosition] = useState({ lat: 0, lng: 0 });

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the address string
    const addressString = `${address.flatnumber}, ${address.streetname}, ${address.landmark}, ${address.city}, ${address.pincode}`;

    // Get customerid from cookies
    const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA'); // Example hashcode
    const customerId = encodedCustomerId !== null
      ? decodeURIComponent(atob(encodedCustomerId))
      : null;

    try {
      // Construct the payload
      const payload = {
        addressid: address.id,
        customerid: customerId,
        name: address.name,
        mobile: address.mobile,
        pincode: address.pincode,
        flatnumber: address.flatnumber,
        streetname: address.streetname,
        landmark: address.landmark,
        city: address.city,
        address: addressString,
        latitude: mapPosition.lat.toString(),  // Ensure latitude is a string
        longitude: mapPosition.lng.toString(), // Ensure longitude is a string
        isDefault: address.isDefault ? "1" : "0"  // Convert boolean to string
      };


      const response = await fetch(isEditing ? SummaryApi.updateAddress.url : SummaryApi.uploadAddress.url, {
        method: isEditing ? SummaryApi.updateAddress.method : SummaryApi.uploadAddress.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_no_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)  // Convert payload to JSON string
      });

      if (!response.ok) {
        throw new Error('Failed to upload address');
      }

      const responseData = await response.json();

      if (responseData.status) {
        toast.success(responseData.message);
        onSave(); // Call the onSave callback to refresh the address list
        onClose(); // Close modal or form
        fetchAddress();
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error('Error uploading address');
      console.error('Error:', error);
    }
  };

  return ReactDOM.createPortal(
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
      <div className='bg-white p-6 rounded-md shadow-lg w-[800px]'>
        <h2 className='text-2xl font-bold mb-4'>{isEditing ? 'Edit Address' : 'New Address'}</h2>
        <form className='flex flex-col space-y-4' onSubmit={handleSubmit}>
          <label className='text-gray-600 mb-2 font-medium'>Full Name:</label>
          <input
            type='text'
            name='name'
            value={address.name}
            onChange={handleChange}
            placeholder='Name'
            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500'
            required
          />
          <label className='text-gray-600 mb-2 font-medium'>Mobile Number:</label>
          <input
            type='text'
            name='mobile'
            value={address.mobile}
            onChange={handleChange}
            placeholder='Mobile number'
            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500'
            required
          />
          <label className='text-gray-600 mb-2 font-medium'>Pincode:</label>
          <input
            type='text'
            name='pincode'
            value={address.pincode}
            onChange={handleChange}
            placeholder='Pincode'
            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500'
            required
          />
          <label className='text-gray-600 mb-2 font-medium'>Flat Number:</label>
          <input
            type='text'
            name='flatnumber'
            value={address.flatnumber}
            onChange={handleChange}
            placeholder='Flatnumber/Apartment'
            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500'
            required
          />
          <label className='text-gray-600 mb-2 font-medium'>Street Name:</label>
          <input
            type='text'
            name='streetname'
            value={address.streetname}
            onChange={handleChange}
            placeholder='Street name'
            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500'
            required
          />
          <label className='text-gray-600 mb-2 font-medium'>Landmark:</label>
          <input
            type='text'
            name='landmark'
            value={address.landmark}
            onChange={handleChange}
            placeholder='Landmark'
            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500'
            required
          />
          <label className='text-gray-600 mb-2 font-medium'>City:</label>
          <input
            type='text'
            name='city'
            value={address.city}
            onChange={handleChange}
            placeholder='City'
            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500'
            required
          />
          <div className='mt-4 flex justify-end space-x-4'>
            <p
              onClick={onClose}
              className='bg-gray-300 cursor-pointer hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md'
            >
              Cancel
            </p>

            <button
              type='submit'
              className='bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md'
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
