import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import SummaryApi from '../common'; // Adjust path as needed

const getItemClass = (isDragging) => `
  ${isDragging ? 'bg-blue-100 shadow-lg' : 'bg-white shadow-sm'}
  flex items-center p-3 mb-2 rounded-md border border-gray-200
  transition-colors duration-150 ease-in-out
`;

const ReorderPopup = ({ onClose, fetchdata }) => {
  const [categories, setCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
        try {
            const response = await fetch(SummaryApi.adminCategory.url, {
                headers: {
                    'Authorization': 'Bearer oakyaecom_no_units_916',
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }

            const dataResponse = await response.json();
            const fetchedCategories = dataResponse?.data || [];

            // Sort categories in ascending order based on product_Id
            const sortedCategories = fetchedCategories.sort((a, b) => {
                // Ensure product_Id is treated as a number for proper sorting
                const idA = typeof a.position_Id === 'string' ? parseInt(a.position_Id, 10) : a.position_Id;
                const idB = typeof b.position_Id === 'string' ? parseInt(b.position_Id, 10) : b.position_Id;
                
                return idA - idB;
            });

            setCategories(sortedCategories);
            setOriginalCategories(sortedCategories);
        } catch (error) {
            console.error('Error fetching categories:', error);
            toast.error(`Failed to fetch categories: ${error.message}`);
        }
    };

    fetchCategories();
}, []);


  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) return;

    const reorderedCategories = Array.from(categories);
    const [movedCategory] = reorderedCategories.splice(source.index, 1);
    reorderedCategories.splice(destination.index, 0, movedCategory);

    const updatedCategories = reorderedCategories.map((cat, index) => ({
      ...cat,
      position_Id: (index + 1).toString()   
    }));

    setCategories(updatedCategories);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(SummaryApi.updateCategoryPositions.url, {
        method: SummaryApi.updateCategoryPositions.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_no_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({categories :categories}),
      });

      if (!response.ok) {
        throw new Error('Failed to update categories');
      }

      const result = await response.json();
      if (result.status) {
        toast.success('Categories reordered successfully!');
        fetchdata();
        onClose();
      } else {
        toast.error(`Failed to reorder categories: ${result.message}`);
      }
    } catch (error) {
      console.error('Error saving categories:', error);
      toast.error(`Failed to save categories: ${error.message}`);
    }
  };

  const handleCancel = () => {
    setCategories(originalCategories); // Revert to original categories
    onClose(); // Close the popup
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Change Category Positions</h2>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="p-4 max-h-96 overflow-y-auto"
              >
                {categories.map((category, index) => (
                  <Draggable key={category.category_id} draggableId={category.category_id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={getItemClass(snapshot.isDragging)}
                        style={{ ...provided.draggableProps.style }}
                      >
                        {category.category_name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex justify-between mt-4">
          <button
            className="border-2 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white transition-all py-1 px-3 rounded-full"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="border-2 border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white transition-all py-1 px-3 rounded-full"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReorderPopup;
