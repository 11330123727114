import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const AssignProductsModal = ({ banner, onClose, fetchData }) => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchProductsAndCategories = async () => {
            setLoading(true);
            try {
                // Fetch products
                const productsResponse = await fetch(SummaryApi.getAllBannerProduct.url, {
                    method: SummaryApi.getAllBannerProduct.method,
                    headers: {
                        'Authorization': 'Bearer oakyaecom_no_units_916',
                    },
                    body: JSON.stringify({
                        bannerid: banner.banner_id,
                    }),
                });

                if (!productsResponse.ok) {
                    throw new Error('Failed to retrieve products');
                }

                const productsData = await productsResponse.json();

                if (productsData.status) {
                    const productsWithNumbers = productsData.products.map(product => ({
                        ...product,
                        product_id: Number(product.product_id),
                    }));

                    // Determine initially selected products
                    const initialSelectedProducts = productsWithNumbers
                        .filter(product => product.active === 1)
                        .map(product => product.product_id);

                    // Sort products to display initially selected products on top
                    const sortedProducts = productsWithNumbers.sort((a, b) => {
                        const aSelected = initialSelectedProducts.includes(a.product_id) ? 1 : 0;
                        const bSelected = initialSelectedProducts.includes(b.product_id) ? 1 : 0;
                        return bSelected - aSelected;
                    });

                    setProducts(sortedProducts);
                    setSelectedProducts(initialSelectedProducts);
                } else {
                    toast.error(productsData.message);
                }

                // Fetch categories
                const categoriesResponse = await fetch(SummaryApi.adminCategory.url, {
                    method: SummaryApi.adminCategory.method,
                    headers: {
                        'Authorization': 'Bearer oakyaecom_no_units_916',
                    },
                });

                if (!categoriesResponse.ok) {
                    throw new Error('Failed to retrieve categories');
                }

                const categoriesData = await categoriesResponse.json();

                if (categoriesData.status) {
                    setCategories(categoriesData.data);
                } else {
                    toast.error(categoriesData.message);
                }
            } catch (error) {
                toast.error('Error retrieving data');
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductsAndCategories();
    }, [banner.banner_id]);

    const handleProductChange = (event) => {
        const productId = Number(event.target.value);
        setSelectedProducts(prev =>
            prev.includes(productId)
                ? prev.filter(id => id !== productId)
                : [...prev, productId]
        );
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(Number(event.target.value));
    };

    const handleClearFilter = () => {
        setSelectedCategory('');
        setSearchQuery('');
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleSave = async () => {
        try {
            const response = await fetch(SummaryApi.updateBanner.url, {
                method: SummaryApi.updateBanner.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_no_units_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    bannerid: banner.banner_id,
                    product_ids: selectedProducts,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update banner');
            }

            const responseData = await response.json();

            if (responseData.status) {
                toast.success('Banner updated successfully');
                fetchData();
                onClose();
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            toast.error('Error updating banner');
            console.error('Error:', error);
        }
    };

    // Filter products based on the selected category and search query
    const filteredProducts = products
        .filter(product =>
            (selectedCategory === '' || selectedCategory === 0 || product.category_id === selectedCategory) &&
            product.product_name.toLowerCase().includes(searchQuery)
        );

    return (
        <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center'>
            <div className='bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-3/5 flex flex-col max-h-[80vh] md:mt-[100px]'>
                <div className='flex-grow overflow-y-auto'>
                    <h3 className='text-lg font-semibold mb-4'>Assign Products to Banner {banner.banner_id}</h3>
                    <img src={SummaryApi.bannerImage.url + banner.desktop_img} className='hidden md:block rounded-lg' />
                    <img src={SummaryApi.bannerImage.url + banner.mobile_img} className='md:hidden rounded-lg' />

                    {/* Category Dropdown */}
                    <div className='mb-4'>
                        <label className='block mb-2 text-sm font-medium'>Select Category:</label>
                        <select
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            className='border border-gray-300 rounded p-2 w-full'
                        >
                            <option value=''>All Categories</option>
                            {categories.map(category => (
                                <option key={category.category_id} value={category.category_id}>
                                    {category.category_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Search Input */}
                    <div className='mb-4'>
                        <label className='block mb-2 text-sm font-medium'>Search Products:</label>
                        <input
                            type='text'
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className='border border-gray-300 rounded p-2 w-full'
                            placeholder='Search by product name'
                        />
                    </div>

                    {/* Clear Filter Button */}
                    <button
                        onClick={handleClearFilter}
                        className='px-4 py-2 bg-red-500 text-white rounded mb-4'
                    >
                        Clear Filter
                    </button>

                    {loading ? (
                        <div className='flex flex-col items-center justify-center min-h-40 md:min-h-60 mb-4'>
                            <div className='border-t-4 border-blue-500 border-solid rounded-full w-12 h-12 animate-spin mb-4'></div>
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <div className='flex-grow h-40 md:h-60 overflow-y-auto mb-4'>
                            {filteredProducts.length === 0 ? (
                                <p>No products available.</p>
                            ) : (
                                filteredProducts.map(product => (
                                    <div key={product.product_id} className='flex items-center mb-2'>
                                        <input
                                            type='checkbox'
                                            value={product.product_id}
                                            checked={selectedProducts.includes(product.product_id)}
                                            onChange={handleProductChange}
                                            className='mr-2'
                                        />
                                        <img
                                            src={SummaryApi.productImage.url + product.product_img1}
                                            alt={product.product_name}
                                            className='h-12 w-12 object-contain rounded mr-2'
                                        />
                                        <label className='line-clamp-1'>{product.product_name}</label>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>

                <div className='flex justify-between mt-4'>
                    <button
                        onClick={onClose}
                        className='px-6 py-2 bg-gray-300 text-gray-800 rounded mr-2'
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className='px-6 py-2 bg-blue-500 text-white rounded'
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AssignProductsModal;