import React, { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { MdDelete } from 'react-icons/md';
import { FaCloudUploadAlt } from 'react-icons/fa';
import DisplayImage from './DisplayImage'; // If needed
import { toast } from 'react-toastify'; // If needed
import Switch from 'react-switch';
import SummaryApi from '../common';

const AdminEditCategory = ({ onClose, categoryData, fetchdata }) => {
  const [data, setData] = useState({
    categoryid: categoryData?.category_id || '',
    categoryname: categoryData?.category_name || '',
    image: categoryData?.category_image || '',
    imageUrl: categoryData?.category_image ? SummaryApi.image.url + categoryData.category_image : '',
    isactive: categoryData?.is_active === "0" ? false : true, // Initialize active state from categoryData
  });

  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUploadCategoryImage = async (e) => {
    const file = e.target.files[0]; // Get the first (and presumably only) file from the FileList
    if (file) {
      setData((prev) => ({
        ...prev,
        image: file, // Store the file object in your state
        imageUrl: URL.createObjectURL(file) // Store the URL for displaying the image
      }));
    }
  };

  const handleDeleteCategoryImage = () => {
    setData((prev) => ({
      ...prev,
      image: '',
      imageUrl: ''
    }));
  };

  const handleActiveToggle = (checked) => {
    setData((prev) => ({
      ...prev,
      isactive: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.image === "") {
      toast.error('Image upload required');
      return;
    }
    setLoading(true); // Set loading to true when form is submitted
    try {
      const formData = new FormData();
      formData.append('categoryid', data.categoryid);
      formData.append('categoryname', data.categoryname);
      formData.append('image', data.image);
      formData.append('isactive', data.isactive ? 1 : 0);
      const response = await fetch(SummaryApi.updateCategory.url, {
        method: SummaryApi.updateCategory.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_no_units_916',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update category');
      }

      const responseData = await response.json();

      if (responseData.status) {
        toast.success(responseData.message);
        onClose();
        fetchdata();
      } else {
        toast.error(responseData.message);
        if(responseData.active){
          data.isactive = true
        }
      }
    } catch (error) {
      toast.error('Error updating category');
      console.error('Error:', error);
    } finally {
      setLoading(false); // Reset loading state after response
    }
  };

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10'>
      <div className='relative bg-white p-4 rounded w-full max-w-2xl max-h-[80%] overflow-y-auto'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Update Category</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 h-full pb-5' onSubmit={handleSubmit}>
          <label htmlFor='categoryname'>Category Name:</label>
          <input
            type='text'
            id='category'
            placeholder='Enter category name'
            name='categoryname'
            value={data.categoryname}
            onChange={handleOnChange}
            className='p-2 h-10 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='image' className='mt-3'>
            Category Image:
          </label>
          <label htmlFor='uploadCategoryImageInput'>
            <div className={`p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer ${loading ? 'cursor-not-allowed' : ''}`}>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'>
                  <FaCloudUploadAlt />
                </span>
                <p className='text-sm'>Upload Category Image</p>
                <input
                  type='file'
                  id='uploadCategoryImageInput'
                  className='hidden'
                  onChange={handleUploadCategoryImage}
                />
              </div>
            </div>
          </label>

          {data.imageUrl && (
            <div className='flex items-center gap-2 mt-3'>
              <div className='relative group'>
                <img
                  src={data.imageUrl}
                  alt='Uploaded Image'
                  className='bg-slate-100 border cursor-pointer w-28'
                  onClick={() => {
                    setOpenFullScreenImage(true);
                    setFullScreenImage(data.imageUrl);
                  }}
                />

                <div
                  className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer'
                  onClick={handleDeleteCategoryImage}
                >
                  <MdDelete />
                </div>
              </div>
            </div>
          )}

          <div className='flex items-center mt-3'>
            <Switch
              onChange={handleActiveToggle}
              checked={data.isactive == 1}
              height={24}
              width={78}
              handleDiameter={20}
              handleStyle={{
                backgroundColor: '#4caf50',
                boxShadow: '0 0 2px 3px rgba(0,0,0,0.1)',
              }}
              onColor='#008000'
              offColor='#D22B2B'
              checkedIcon={<div className='flex justify-center items-center h-full text-sm ml-3 text-white'>Active</div>}
              uncheckedIcon={<div className='flex justify-center items-center h-full text-sm mr-5 text-white'>Inactive</div>}
              className='mt-2'
            />
          </div>

          <button
            type='submit'
            className='px-3 py-2 bg-red-600 text-white mt-5 hover:bg-red-700'
          >
            Update Category
          </button>
        </form>

        {loading && (
          <div className='absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center'>
            <div className='text-lg font-bold'>Updating Category...</div>
          </div>
        )}

        {/* Display full screen image modal */}
        {openFullScreenImage && (
          <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />
        )}
      </div>
    </div>
  );
};

export default AdminEditCategory;
