import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import SummaryApi from '../common';
import UploadBrand from '../components/UploadBrand';
import AdminBrandCard from '../components/AdminBrandCard';
import { toast } from 'react-toastify';


const AllBrands = () => {
  const [openUploadBrand, setOpenUploadBrand] = useState(false);
  const [allBrand, setAllBrand] = useState([]);

  const fetchAllBrand = async () => {
    try {
      const response = await fetch(SummaryApi.adminBrand.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_no_units_916',
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch brands');
      }
  
      const dataResponse = await response.json();
  
      // Reverse the data
      const reversedBrands = (dataResponse?.data || []).reverse();
      setAllBrand(reversedBrands); // Set reversed data
    } catch (error) {
      console.error('Error fetching brands:', error);
      // Optionally, use a notification or a toast to show the error
      toast.error(`Failed to fetch brands: ${error.message}`);
    }
  };
  

  useEffect(() => {
    fetchAllBrand();
  }, []);

  return (
    <div>
      <div className='bg-white py-2 px-4 flex flex-col sm:flex-row justify-between items-center'>
        <h2 className='font-bold text-lg'>All Brands</h2>
        <div className='flex'>
          <div className='flex items-center mx-2 text-sm '>
            <div>Active</div> 
            <div className='mx-2 text-lg text-[#008000]'><FaCheckCircle/></div> 
            <div className='ml-2'>InActive</div> 
            <div className='mx-2 text-lg text-[#D22B2B]'><FaTimesCircle /></div>
          </div>
          <button
            className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all py-1 px-3 rounded-full'
            onClick={() => setOpenUploadBrand(true)}
          >
            Upload Brand
          </button>
        </div>
        
      </div>

      {/* Display categories using AdminCategoryCard */}
      <div className='flex items-center flex-wrap gap-5 py-4 overflow-y-scroll'>
        {allBrand.map((product, index) => (
          <AdminBrandCard key={index} data={product} fetchdata={fetchAllBrand} />
        ))}
      </div>

      {/* Upload product component */}
      {openUploadBrand && <UploadBrand onClose={() => setOpenUploadBrand(false)} fetchdata={fetchAllBrand} />}
    </div>
  );
};

export default AllBrands;
