import React, { useContext, useEffect, useState } from 'react';
import SummaryApi from '../common';
import Context from '../context';
import displayINRCurrency from '../helpers/displayCurrency';
import { MdDelete } from 'react-icons/md';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import scrollTop from '../helpers/scrollTop';
import CartEmpty from '../assest/cart-empty.png';

const Cart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadingCart = new Array(4).fill(null);
  const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA');
  const customerId = encodedCustomerId
    ? decodeURIComponent(atob(encodedCustomerId))
    : null;
  const { cartProductCount, fetchUserAddToCart } = useContext(Context);
  console.log(cartProductCount)

  // Helper function to encode product_id
  const encodeProductId = (id) => {
    if (!id) return '';
    return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
  };

  const fetchData = async () => {
    try {
      const response = await fetch(SummaryApi.addToCartProductView.url, {
        method: SummaryApi.addToCartProductView.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_no_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerid: customerId,
        }),
      });

      const responseData = await response.json();
      if (responseData.status) {
        setData(responseData.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Ensure loading state is set to false after data is fetched
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const increaseQty = async (id, qty) => {
    qty = parseInt(qty);
    const response = await fetch(SummaryApi.updateCartProduct.url, {
      method: SummaryApi.updateCartProduct.method,
      headers: {
        'Authorization': 'Bearer oakyaecom_no_units_916',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        cartid: id,
        quantity: qty + 1,
      }),
    });

    const responseData = await response.json();

    if (responseData.status) {
      fetchData();
      fetchUserAddToCart();
    }
  };

  const decreaseQty = async (id, qty) => {
    qty = parseInt(qty);
    if (qty >= 2) {
      const response = await fetch(SummaryApi.updateCartProduct.url, {
        method: SummaryApi.updateCartProduct.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_no_units_916',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          cartid: id,
          quantity: qty - 1,
        }),
      });

      const responseData = await response.json();

      if (responseData.status) {
        fetchData();
        fetchUserAddToCart();
      }
    }
  };

  const deleteCartProduct = async (id) => {
    const response = await fetch(SummaryApi.deleteCartProduct.url, {
      method: SummaryApi.deleteCartProduct.method,
      headers: {
        'Authorization': 'Bearer oakyaecom_no_units_916',
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        cartid: id,
      }),
    });

    const responseData = await response.json();
    if (responseData.status) {
      fetchData();
      fetchUserAddToCart();
    }
  };

  const totalQty = data.reduce((previousValue, currentValue) => {
    const quantity = parseInt(currentValue.quantity);
    return previousValue + quantity;
  }, 0);

  const price = data.reduce((preve, curr) => preve + (curr.quantity * curr?.product_details?.price), 0);
  const totalPrice = data.reduce((preve, curr) => preve + (curr.quantity * curr?.product_details?.after_discount_price), 0);
  const discount = price - totalPrice;

  return (
    <div className='container mx-auto'>
      {loading && (
        <div className='bg-white p-6 rounded-lg shadow-md text-center mt-4'>
          {loadingCart.map((_, index) => (
            <div
              key={index}
              className='w-full bg-gray-200 h-32 my-2 border border-gray-300 animate-pulse rounded'
            ></div>
          ))}
        </div>
      )}

      {!loading && cartProductCount === 0 && (
        <div className='bg-white p-6 rounded-lg shadow-md text-center mt-4'>
          <h2 className='text-2xl font-bold mb-4'>Your Cart is Empty</h2>
          <img src={CartEmpty} alt='Cart Empty' className='flex mx-auto' />
          <p className='text-gray-600 mb-4'>It looks like you haven't added any items to your cart yet.</p>
          <p className="text-gray-600 mb-4">Browse our products and start shopping!</p>
          <Link to='/' className='text-red-600'>
            <button className='relative bg-gradient-to-r from-white to-red-600 text-white font-semibold py-2 px-4 border border-red-600 rounded-lg shadow-md overflow-hidden hover:from-red-600 hover:to-white'>
              <span className='absolute inset-0 bg-gradient-to-r from-red-600 to-white opacity-50'></span>
              <span className='relative z-10'>Browse Products</span>
            </button>
          </Link>
        </div>
      )}

      {!loading && data.length > 0 && (
        <div className='text-center text-lg my-3'>
          <div className='flex flex-col lg:flex-row gap-5 p-0'>
            {/* View Product */}
            <div className='bg-white p-4 shadow-lg rounded-lg mb-4'>
              <div className='w-full max-w-3xl lg:max-w-6xl lg:min-w-[800px]'>
                {data.slice().reverse().map((product, index) => (
                  <div
                    key={product?.cart_id + 'AddToCartLoading'}
                    className={`w-full bg-white h-auto lg:h-64 my-2 ${index === data.length - 1 ? '' : 'border-b border-slate-300'} rounded grid grid-cols-[128px,1fr] lg:grid-cols-[215px,1fr] relative`}
                  >
                    <Link
                      to={`/product/${encodeProductId(product?.product_id)}`}
                      className='w-[120px] h-[120px] lg:w-56 lg:h-56 m-2'
                      onClick={scrollTop}
                    >
                      <img
                        src={SummaryApi.productImage.url + product?.product_details?.product_img1}
                        className='w-full h-full object-scale-down mix-blend-multiply m-1 lg:m-2'
                      />
                    </Link>
                    <div className='px-4 py-2 m-2 lg:m-8 lg:relative'>
                      {/**delete product */}
                      <div
                        className='absolute right-0 top-0 text-red-600 rounded-md p-2 bg-slate-100 hover:bg-red-600 hover:text-white cursor-pointer'
                        onClick={() => deleteCartProduct(product?.cart_id)}
                      >
                        <MdDelete />
                      </div>
                      <div className='flex items-center justify-between'>
                        <h2 className='text-sm lg:text-xl line-clamp-2 max-w-[120px] lg:max-w-xl'>
                          {product?.product_details?.product_name}
                        </h2>
                      </div>
                      <div className='flex items-center justify-between'>
                        <p className='text-sm capitalize text-slate-500'>
                          {product?.product_details.category_data.category_name}
                        </p>
                      </div>
                      <div className='flex items-center justify-between'>
                        <h2 className='hidden lg:text-sm lg:line-clamp-2 lg:max-w-lg'>
                          {product?.product_details?.product_description}
                        </h2>
                      </div>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-baseline'>
                          <p className='text-red-400 font-medium text-xs lg:text-sm line-through ml-1'>
                            {displayINRCurrency(product?.product_details?.price)}
                          </p>
                          <p className='text-red-600 font-medium lg:text-lg ml-2'>
                            {displayINRCurrency(product?.product_details?.after_discount_price)}
                          </p>
                        </div>
                      </div>
                      <div className='flex items-center gap-3 mt-1 my-1 lg:mx-4 '>
                        <button
                          className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded'
                          onClick={() => decreaseQty(product?.cart_id, product?.quantity)}
                        >
                          -
                        </button>
                        <span>{product?.quantity}</span>
                        <button
                          className='border border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-6 h-6 flex justify-center items-center rounded'
                          onClick={() => increaseQty(product?.cart_id, product?.quantity)}
                        >
                          +
                        </button>
                      </div>
                      <div className='m-1 lg:m-2 '>
                        <p className='absolute bottom-0 right-0 text-black text-xs font-semibold lg:text-lg'>
                          {displayINRCurrency(product?.product_details?.after_discount_price * product?.quantity)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Summary */}
            <div className='mt-5 lg:mt-0 w-full max-w-sm'>
              <div className='h-auto bg-white drop-shadow-lg p-2'>
                <h2 className='px-4 py-1 font-medium text-lg text-slate-600 uppercase my-3'>
                  Price Details
                </h2>
                <div className='flex items-center justify-between px-4 gap-2 text-md my-3'>
                  <p>Price({totalQty} items)</p>
                  <p>{displayINRCurrency(price)}</p>
                </div>

                <div className='flex items-center justify-between px-4 gap-2 text-md my-3'>
                  <p>Discount</p>
                  <p className='text-green-700'>-{displayINRCurrency(discount)}</p>
                </div>

                <div className='border-b-2 border-dashed mx-4 my-2 border-slate-300 rounded-lg'></div>

                <div className='flex items-center justify-between px-4 gap-2 font-medium text-lg my-3'>
                  <p>Total Price</p>
                  <p>{displayINRCurrency(totalPrice)}</p>
                </div>
                <Link
                  to={data.length > 0 ? '/check-out' : '#'}
                  className={`flex items-center justify-center ${data.length === 0 ? 'pointer-events-none opacity-50' : ''
                    }`}
                  onClick={data.length > 0 ? scrollTop : (e) => e.preventDefault()}
                >
                  <button
                    className='bg-red-600 p-2 hover:bg-green-500 font-medium text-white rounded-lg w-full mt-2 mx-4 my-3'
                    disabled={data.length === 0}
                  >
                    Proceed to Buy
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
