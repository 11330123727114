import React, { useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { MdDelete } from 'react-icons/md';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { toast } from 'react-toastify'; // If needed
import DisplayImage from './DisplayImage'; // If needed
import SummaryApi from '../common'; // Adjust the import path as needed

const BannerEditModal = ({ onClose, banner, fetchData }) => {
    const [data, setData] = useState({
        bannerid: banner?.banner_id || '',
        image1: banner?.desktop_img || '',
        image2: banner?.mobile_img || '',
        image1Url: banner?.desktop_img ? SummaryApi.bannerImage.url + banner.desktop_img : '',
        image2Url: banner?.mobile_img ? SummaryApi.bannerImage.url + banner.mobile_img : '',
    });

    const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleUploadImage = (e, imageKey) => {
        const file = e.target.files[0];
        if (file) {
            setData((prev) => ({
                ...prev,
                [imageKey]: file,
                [`${imageKey}Url`]: URL.createObjectURL(file),
            }));
        }
    };

    const handleDeleteImage = (imageKey) => {
        setData((prev) => ({
            ...prev,
            [imageKey]: '',
            [`${imageKey}Url`]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data.image1 || !data.image2) {
            toast.error('Both images are required');
            return;
        }

        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('bannerid', data.bannerid);
            formData.append('image1', data.image1);
            formData.append('image2', data.image2);

            const response = await fetch(SummaryApi.editBannerImage.url, {
                method: SummaryApi.editBannerImage.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_no_units_916',
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to update banner');
            }

            const responseData = await response.json();

            if (responseData.status) {
                toast.success(responseData.message);
                onClose();
                fetchData();
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            toast.error('Error updating banner');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10'>
            <div className='relative bg-white p-4 rounded w-full max-w-2xl max-h-[80%] overflow-y-auto'>
                <div className='flex justify-between items-center pb-3'>
                    <h2 className='font-bold text-lg'>Edit Banner {banner.banner_id}</h2>
                    <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
                        <CgClose />
                    </div>
                </div>

                <form className='grid p-4 gap-2 h-full pb-5' onSubmit={handleSubmit}>
                    <div className='flex'>
                        <div className='flex-1 px-2'>
                            <label htmlFor='image1' className='mt-3'>
                                Desktop Image:
                            </label>
                            <label htmlFor='uploadBannerImage1'>
                                <div className={`p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer ${loading ? 'cursor-not-allowed' : ''}`}>
                                    <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                                        <span className='text-4xl'>
                                            <FaCloudUploadAlt />
                                        </span>
                                        <p className='text-sm'>Upload Image</p>
                                        <input
                                            type='file'
                                            id='uploadBannerImage1'
                                            className='hidden'
                                            onChange={(e) => handleUploadImage(e, 'image1')}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div className='flex-1 px-2'>
                            {data.image1Url && (
                                <div className='flex flex-col'>
                                    <p>Desktop Image Preview:</p>
                                    <div className='relative group'>
                                        <img
                                            src={data.image1Url}
                                            alt='Uploaded Image 1'
                                            className='object-contain cursor-pointer w-auto h-32'
                                            onClick={() => {
                                                setOpenFullScreenImage(true);
                                                setFullScreenImage(data.image1Url);
                                            }}
                                        />

                                        <div
                                            className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer'
                                            onClick={() => handleDeleteImage('image1')}
                                        >
                                            <MdDelete />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='flex'>
                        <div className='flex-1 px-2'>
                            <label htmlFor='image2' className='mt-3'>
                                Mobile Image:
                            </label>
                            <label htmlFor='uploadBannerImage2'>
                                <div className={`p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer ${loading ? 'cursor-not-allowed' : ''}`}>
                                    <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                                        <span className='text-4xl'>
                                            <FaCloudUploadAlt />
                                        </span>
                                        <p className='text-sm'>Upload Image</p>
                                        <input
                                            type='file'
                                            id='uploadBannerImage2'
                                            className='hidden'
                                            onChange={(e) => handleUploadImage(e, 'image2')}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div className='flex-1 px-2'>
                            {data.image2Url && (
                                <div className='flex flex-col'>
                                    <p>Mobile Image Preview:</p>
                                    <div className='relative group'>
                                        <img
                                            src={data.image2Url}
                                            alt='Uploaded Image 2'
                                            className=' object-contain cursor-pointer w-auto h-32'
                                            onClick={() => {
                                                setOpenFullScreenImage(true);
                                                setFullScreenImage(data.image2Url);
                                            }}
                                        />
                                        <div
                                            className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer'
                                            onClick={() => handleDeleteImage('image2')}
                                        >
                                            <MdDelete />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <button
                        type='submit'
                        className='px-3 py-2 bg-red-600 text-white mt-5 hover:bg-red-700'
                    >
                        Update Banner
                    </button>
                </form>

                {loading && (
                    <div className='absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center'>
                        <div className='text-lg font-bold'>Updating Banner...</div>
                    </div>
                )}

                {/* Display full screen image modal */}
                {openFullScreenImage && (
                    <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />
                )}
            </div>
        </div>
    );
};

export default BannerEditModal;
