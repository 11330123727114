import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import AdminPanel from '../pages/AdminPanel';
import AllProducts from '../pages/AllProducts';
import CategoryProduct from '../pages/CategoryProduct';
import ProductDetails from '../pages/ProductDetails';
import Cart from '../pages/Cart';
import SearchProduct from '../pages/SearchProduct';
import ProductForm from '../components/ProductForm';
import AllCategory from '../pages/AllCategory';
import AllBrands from '../pages/AllBrands';
import AllSubCategory from '../pages/AllSubCategory';
import CheckOut from '../pages/CheckOut';
import AllCustomers from '../pages/AllCustomers';
import AdminLogin from '../pages/AdminLogin';
import ChangePassword from '../pages/ChangePassword';
import Dashboard from '../pages/Dashboard';
import OrderConfirmation from '../pages/OrderConfirmation';
import Orders from '../pages/Orders';
import AllOrders from '../pages/AllOrders';
import EditOrder from '../pages/EditOrder';
import TodayOrders from '../pages/TodayOrders';
import WeeklyOrders from '../pages/WeeklyOrders';
import MonthlyOrders from '../pages/MonthlyOrders';
import Invoice from '../pages/Invoice';
import OrderHistory from '../pages/OrderHistory';
import AllBanner from '../pages/AllBanner';
import BannerProductScreen from '../pages/BannerProductScreen';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { path: "", element: <Home /> }, // Home page as default
            { path: "login", element: <Login /> },
            { path: "sign-up", element: <SignUp /> },
            { path: "product-category", element: <CategoryProduct /> },
            { path: "product/:id", element: <ProductDetails /> },
            { path: "banner/:id", element: <BannerProductScreen /> },
            { path: 'cart', element: <Cart /> },
            { path: 'check-out', element: <CheckOut /> },
            { path: 'order-confirmation', element: <OrderConfirmation /> },
            { path: 'my-orders', element: <Orders /> },
            { path: "search", element: <SearchProduct /> },
            { path: "admin-login", element: <AdminLogin /> },
            {
                path: "admin-panel",
                element: <AdminPanel />,
                children: [
                    { path: "", element: <Dashboard /> }, // Default route: dashboard
                    { path: "dashboard", element: <Dashboard /> },
                    { path: "all-customers", element: <AllCustomers /> },
                    { path: "all-products", element: <AllProducts /> },
                    { path: "all-category", element: <AllCategory /> },
                    { path: "all-brand", element: <AllBrands /> },
                    { path: "all-subCategory", element: <AllSubCategory /> },
                    { path: "all-banner", element: <AllBanner /> },
                    { path: "all-orders", element: <AllOrders /> },
                    { path: "today-orders", element: <TodayOrders /> },
                    { path: "weekly-orders", element: <WeeklyOrders /> },
                    { path: "monthly-orders", element: <MonthlyOrders /> },
                    { path: "change-password", element: <ChangePassword /> },
                    { path: "edit-order", element: <EditOrder /> },
                    { path: "order-history", element: <OrderHistory /> },
                    { path: "invoice/:orderId", element: <Invoice /> }
                ]
            },
            { path: "add-product", element: <ProductForm /> },
        ]
    }
]);


export default router;
