import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdCheckmarkCircle } from "react-icons/io";

const OrderConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { order } = location.state || {};

    useEffect(() => {
        if (order === undefined) {
            navigate('/'); // Render nothing if there's no order (should not normally happen with the useEffect redirect)
        }
    }, [])

    // Block navigation back to the previous page
    useEffect(() => {
        const unblock = () => {
            navigate('/'); // Always redirect to home if navigation is attempted
        };

        window.history.pushState(null, null, window.location.pathname); // Clears history

        window.addEventListener('popstate', unblock); // Listen to popstate event

        return () => window.removeEventListener('popstate', unblock); // Cleanup listener
    }, [navigate]);

    const handleContinueShopping = () => {
        navigate('/');
    };

    const handleViewOrders = () => {
        navigate('/my-orders');
    };
    
    
    console.log(order)
    return (
        <section className='min-h-screen bg-gray-100 flex flex-col items-center pt-8 md:pt-12'>
            <div className='bg-white p-6 md:p-8 rounded-lg shadow-lg w-full max-w-3xl'>
                <div className='text-center mb-6'>
                    <h2 className='text-3xl font-bold text-gray-800 flex items-center justify-center'>
                        <span className='mr-2'>Order Confirmed!</span>
                        <IoMdCheckmarkCircle className='text-3xl text-green-500' />
                    </h2>
                    <p className='text-lg text-gray-600 mt-2'>
                        Thank you for your purchase! Your order has been placed successfully.
                    </p>
                </div>
                {/* Order Summary Section */}
                <div className='bg-gray-50 p-4 md:p-6 rounded-md'>
                    <div className='flex flex-col space-y-4'>
                        <div className='flex justify-center gap-2'>
                            <span className='font-medium text-gray-700'>Order Code:</span>
                            <span>{order?.order_code}</span>
                        </div>
                    </div>
                </div>
                <div className='border-t border-gray-300 my-4'></div>
                {/* Navigation Buttons */}
                <div className='flex justify-between mt-6'>
                    <button
                        onClick={handleContinueShopping}
                        className='bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600'>
                        Continue Shopping
                    </button>
                    <button
                        onClick={handleViewOrders}
                        className='bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600'>
                        View Orders
                    </button>
                </div>
            </div>
        </section>
    );
};

export default OrderConfirmation;
