const { default: SummaryApi } = require("../common")

const fetchCategoryWiseProduct = async(category)=>{
    const response = await fetch(SummaryApi.categoryWiseProduct.url,{
        method : SummaryApi.categoryWiseProduct.method,
        headers: {
            'Authorization': 'Bearer oakyaecom_no_units_916',
            'Content-Type': 'application/json',
        },
        body : JSON.stringify({
            categoryid : category
        })
    })

    const dataResponse = await response.json()

    return dataResponse
}

export default fetchCategoryWiseProduct