import React, { useState, useEffect } from 'react';
import SummaryApi from '../common';
import displayINRCurrency from '../helpers/displayCurrency';
import OrderDetailPopup from '../components/OrderDetailPopup';
import { Link, useLocation } from 'react-router-dom';
import OrderEmpty from '../assest/empty-orders.png';

const PlaceholderOrder = () => (
    <div className="animate-pulse relative bg-gradient-to-br from-white to-gray-100 shadow-lg rounded-lg p-4 md:p-6 mb-6 flex flex-col">
        <div className="absolute top-2 right-2 bg-gray-300 h-4 w-16 rounded-md"></div>
        <div className="h-6 bg-gray-300 rounded-md mb-2 mt-4"></div>
        <div className="h-6 bg-gray-300 rounded-md mb-1 w-3/4"></div>
        <div className="h-6 bg-gray-300 rounded-md mb-2 w-1/2"></div>
        <div className="h-6 bg-gray-300 rounded-md mb-1 w-5/6"></div>
        <div className="h-6 bg-gray-300 rounded-md mb-1 w-2/3"></div>
        <div className="h-6 bg-gray-300 rounded-md mb-4"></div>
        <div className="flex flex-row justify-between items-center border-t pt-2 md:pt-4 mt-auto space-y-2 md:space-y-0">
            <div className="h-6 bg-gray-300 rounded-md w-1/4"></div>
            <div className="h-6 bg-gray-300 rounded-md w-1/4"></div>
        </div>
    </div>
);

const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });
    const formattedTime = dateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    return `${formattedDate} ${formattedTime}`;
};

const OrderHistory = () => {
    const location = useLocation();
    const { customerID } = location.state || {};
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch(SummaryApi.orderDetailsByID.url, {
                    method: SummaryApi.orderDetailsByID.method,
                    headers: {
                        'Authorization': 'Bearer oakyaecom_no_units_916',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ customerid: customerID }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.status) {
                    // Sort orders by order_id in descending order
                    const sortedOrders = data.data.sort((a, b) => b.order_id - a.order_id);
                    setOrders(sortedOrders);
                } else {
                    setOrders([]);
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
                setError('Error fetching orders');
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const openOrderDetails = (order) => {
        setSelectedOrder(order);
    };

    const closeOrderDetails = () => {
        setSelectedOrder(null);
    };

    if (loading) {
        // Display placeholders while loading
        return (
            <div className="p-4 md:p-6 max-w-6xl mx-auto">
                {[...Array(3)].map((_, index) => (
                    <PlaceholderOrder key={index} />
                ))}
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-4 md:p-6 max-w-6xl mx-auto">
                <p className="text-center text-red-500">{error}</p>
            </div>
        );
    }
    const getStatusClass = (status) => {
        let statusClass = 'bg-gray-200 text-gray-800'; // Default color for unknown status

        switch (status) {
            case 'Pending':
                statusClass = 'bg-yellow-200 text-yellow-800';
                break;
            case 'Order placed':
                statusClass = 'bg-orange-200 text-orange-800';
                break;
            case 'Processing':
                statusClass = 'bg-purple-200 text-purple-800';
                break;
            case 'Order confirmed':
                statusClass = 'bg-blue-200 text-blue-800';
                break;
            case 'Order in transit':
                statusClass = 'bg-teal-200 text-teal-800';
                break;
            case 'Delivered':
                statusClass = 'bg-green-200 text-green-800';
                break;
            case 'Cancelled':
                statusClass = 'bg-red-200 text-red-800';
                break;
            default:
                statusClass = 'bg-gray-200 text-gray-800'; // Default color for unknown status
                break;
        }

        return statusClass;
    };

    return (
        <div className="p-4 md:p-6 max-w-6xl mx-auto">
            {orders.length > 0 && (
                <h2 className="text-2xl md:text-4xl font-bold mb-6 text-gray-900">Order History</h2>
            )}
            {orders.length === 0 ? (
                <div className="bg-white p-4 md:p-6 rounded-lg shadow-md text-center">
                    <h3 className="text-lg md:text-xl font-semibold">No Orders Found</h3>
                    <img src={OrderEmpty} alt='No Orders' className='flex mx-auto' />
                    <p className="text-gray-500 mt-2">You haven't placed any orders yet.</p>
                    <p className="text-gray-500 mt-1 mb-4">Browse our products and start shopping!</p>
                    <Link to='/' className='text-red-600'>
                        <button className='relative bg-gradient-to-r from-white to-red-600 text-white font-semibold py-2 px-4 border border-red-600 rounded-lg shadow-md overflow-hidden hover:from-red-600 hover:to-white'>
                            <span className='absolute inset-0 bg-gradient-to-r from-red-600 to-white opacity-50'></span>
                            <span className='relative z-10'>Browse Products</span>
                        </button>
                    </Link>
                </div>
            ) : (
                <div className="space-y-2">
                    {orders.map((order) => (
                        <div
                            key={order.order_id}
                            className="relative bg-gradient-to-br from-white to-gray-100 shadow-lg rounded-lg p-4 md:p-6 mb-6 flex flex-col"
                        >
                            {/* Order Status in Top Right Corner */}
                            <p className={`absolute top-2 right-2 text-xs font-bold px-2 py-1 rounded-lg shadow-md ${getStatusClass(order.order_status)}`}>
                                {order.order_status}
                            </p>


                            {/* Order Code and Date */}
                            <div className="flex justify-between items-center mb-2 mt-4">
                                <h3 className="text-lg md:text-xl font-semibold text-gray-800">Order Code: {order.order_code}</h3>
                                <p className="text-xs md:text-sm text-gray-700">{formatDateTime(order.order_date_time)}</p>
                            </div>

                            <div className="mb-1 border-t">
                                <h4 className="text-base md:text-lg font-semibold text-gray-700 mt-4">Customer Information</h4>
                                <div className="flex flex-col space-y-1 mt-2">
                                    <div className="flex">
                                        <span className="text-xs md:text-sm text-gray-700 font-bold w-24">Name:</span>
                                        <span className="text-xs md:text-sm text-gray-700 flex-1">{order.customer_name}</span>
                                    </div>
                                    <div className="flex">
                                        <span className="text-xs md:text-sm text-gray-700 font-bold w-24">Address:</span>
                                        <span className="text-xs md:text-sm text-gray-700 flex-1">{order.order_address}</span>
                                    </div>
                                </div>
                            </div>
                            {/* Flex container for Total Amount and View Details button */}
                            <div className="flex flex-row justify-between items-center border-t pt-2 md:pt-4 mt-auto space-y-2 md:space-y-0">

                                <button
                                    onClick={() => openOrderDetails(order)}
                                    className="bg-blue-500 text-white px-4 py-2 text-xs md:text-sm rounded-lg hover:bg-blue-600 transition duration-300"
                                >
                                    View Details
                                </button>

                                <p className="text-xs md:text-sm font-bold text-gray-800 bg-blue-200 px-2 py-1 rounded-lg shadow-md whitespace-nowrap">
                                    {displayINRCurrency(order.total_amount)}
                                </p>

                            </div>
                        </div>
                    ))}
                </div>
            )}
            {selectedOrder && (
                <OrderDetailPopup order={selectedOrder} onClose={closeOrderDetails} />
            )}
        </div>
    );
};

export default OrderHistory;
