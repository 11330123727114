import SummaryApi from "../common";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const addToCart = async (e, productId, callback) => {
    e?.stopPropagation();
    e?.preventDefault();

    // Get M8V5R1JQ3K0PWT6Z9LXA from cookies (example of how you might do it, adjust as per your implementation)
    const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA');  //M8V5R1JQ3K0PWT6Z9LXA(Random 16-character hashcode stored in cookies)

    const customerId = encodedCustomerId == null
    ? null
    : decodeURIComponent(atob(Cookies.get('M8V5R1JQ3K0PWT6Z9LXA')));  

    // Define the data object with the required parameters
    const data = {
        customerid: customerId,
        productid: productId,
        quantity: "1",
    };

    const response = await fetch(SummaryApi.addToCartProduct.url, {
        method: SummaryApi.addToCartProduct.method, 
        headers: {
            'Authorization': 'Bearer oakyaecom_no_units_916',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(data)
    });

    const responseData = await response.json();

    if (responseData.success) {
        toast.success(responseData.message);
        callback();
    }

    if (responseData.error) {
        toast.error(responseData.message);
    }
    console.log(responseData)
    return responseData;
};

export default addToCart;