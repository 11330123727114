import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import BannerEditModal from '../components/BannerEditModal';
import AssignProductsModal from '../components/AssignProductsModal';

const AllBanner = () => {
    const [banners, setBanners] = useState([]);
    const [editBanner, setEditBanner] = useState(null);
    const [loading, setLoading] = useState(false);
    const [assignProductsBanner, setAssignProductsBanner] = useState(null);

    const fetchBannerImages = async () => {
        setLoading(true);
        try {
            const response = await fetch(SummaryApi.getBannerImages.url, {
                method: SummaryApi.getBannerImages.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_no_units_916',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to retrieve banners');
            }

            const responseData = await response.json();

            if (responseData.status) {
                setBanners(responseData.data);
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            toast.error('Error retrieving banners');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBannerImages();
    }, []);

    const handleEditClick = (banner) => {
        setEditBanner(banner);
    };

    const handleAssignProductsClick = (banner) => {
        setAssignProductsBanner(banner);
    };

    const handleCloseEdit = () => {
        setEditBanner(null);
    };

    const handleCloseAssignProducts = () => {
        setAssignProductsBanner(null);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Banner',
                accessor: 'banner_id',
                Cell: ({ value }) => <div className='text-center'>{value}</div>,
            },
            {
                Header: 'Desktop Image',
                accessor: 'desktop_img',
                Cell: ({ value }) => (
                    <div className='flex justify-center items-center'>
                        <img src={SummaryApi.bannerImage.url + value} alt='Desktop' className='h-[100px] w-auto object-contain rounded' />
                    </div>
                ),
            },
            {
                Header: 'Mobile Image',
                accessor: 'mobile_img',
                Cell: ({ value }) => (
                    <div className='flex justify-center items-center'>
                        <img src={SummaryApi.bannerImage.url + value} alt='Mobile' className='h-[100px] w-auto object-contain rounded' />
                    </div>
                ),
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <div className='flex justify-center items-center'>
                        <button
                            className='px-4 py-2 text-xs md:text-sm bg-blue-500 text-white rounded hover:bg-blue-600 transition'
                            onClick={() => handleEditClick(row.original)}
                        >
                            <span>Update</span>
                        </button>
                        <button
                            className='px-4 py-2 text-xs md:text-sm bg-green-500 text-white rounded hover:bg-green-600 transition ml-2'
                            onClick={() => handleAssignProductsClick(row.original)}
                        >
                            <span className='line-clamp-1'>Assign Products</span>
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const data = useMemo(
        () => banners.filter(banner => banner.is_active),
        [banners]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    return (
        <div className='p-4'>
            <div className='bg-white shadow-md rounded-lg p-4 mb-4'>
                <h2 className='font-bold text-lg'>All Banners</h2>
            </div>

            {loading ? (
                <div className='flex flex-col justify-center items-center h-[65vh]'>
                    <div className='border-t-4 border-blue-500 border-solid rounded-full w-12 h-12 animate-spin'></div>
                    <p className='mt-2'>Loading...</p>
                </div>
            ) : (
                <div className='overflow-x-auto'>
                    <table {...getTableProps()} className='min-w-full border-collapse bg-white shadow-md rounded-lg'>
                        <thead className='bg-gray-100'>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className='border-b p-2 text-center text-gray-600'
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} className='hover:bg-gray-50'>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()} className='border-b p-2 text-center'>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {editBanner && (
                <BannerEditModal
                    banner={editBanner}
                    onClose={handleCloseEdit}
                    fetchData={fetchBannerImages}
                />
            )}

            {assignProductsBanner && (
                <AssignProductsModal
                    banner={assignProductsBanner}
                    onClose={handleCloseAssignProducts}
                    fetchData={fetchBannerImages}
                />
            )}
        </div>
    );
};

export default AllBanner;
