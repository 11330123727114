import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import SummaryApi from '../common';

const CategoryList = () => {
    const [categoryProduct, setCategoryProduct] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const scrollElement = useRef(null);
    

    useEffect(() => {
        const fetchCategoryProduct = async () => {
            try {
                const response = await fetch(SummaryApi.allCategory.url, {
                    headers: {
                        'Authorization': 'Bearer oakyaecom_no_units_916',
                        'Content-Type': 'application/json',
                    },
                });
                const dataResponse = await response.json();
                setCategoryProduct(dataResponse.data || []);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching category products:', error);
                setIsLoading(false);
            }
        };

        fetchCategoryProduct();
    }, []);

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 100; // Adjust scroll amount as needed
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 100; // Adjust scroll amount as needed
    };

    return (
        <div className="container mx-auto md:p-4 shadow-2xl">
            <div className="flex items-center gap-2 md:gap-8 overflow-x-scroll scrollbar-none transition-all" style={{ minHeight: '100px' }} ref={scrollElement}>
                {/* Scroll buttons */}
                <button id="scrollLeftBtn" className="bg-white shadow-md rounded-full p-1 absolute left-0 text-lg hidden md:block" onClick={scrollLeft}>
                    <FaAngleLeft />
                </button>
                <button id="scrollRightBtn" className="bg-white shadow-md rounded-full p-1 absolute right-0 text-lg hidden md:block" onClick={scrollRight}>
                    <FaAngleRight />
                </button>
                {isLoading ? (
                    // Loading placeholders
                    new Array(16).fill(null).map((_, index) => (
                        <div className="cursor-pointer" key={`placeholder-${index}`}>
                            <div className="h-16 w-16 md:w-20 md:h-20 rounded-lg overflow-hidden p-1 flex items-center justify-center bg-gray-200 animate-pulse">
                                {/* Placeholder image */}
                                <div className="w-full h-full"></div>
                            </div>
                            <p className="text-center text-[10px] md:text-base capitalize bg-gray-200 animate-pulse h-4 w-full rounded-full mt-1"></p>
                        </div>
                    ))
                ) : (
                    // Render actual category products
                    categoryProduct.map((product) => (
                        <Link
                            to={`/product-category?category=${encodeURIComponent(product?.category_name)}`}
                            className={`cursor-pointer`}
                            key={product?.category_name}
                        >
                            <div className="h-16 w-16 md:w-20 md:h-20 rounded-lg overflow-hidden p-1 flex items-center justify-center">
                                <img src={SummaryApi.image.url + product.category_image} alt={product.category_name} className="h-16 w-16 md:w-16 md:h-16 object-contain mix-blend-multiply hover:scale-110 transition-all" />
                            </div>
                            <p className="text-center text-[10px] line-clamp-1 md:text-base capitalize">{product.category_name}</p>
                        </Link>
                    ))
                )}
            </div>
        </div>
    );
};

export default CategoryList;
