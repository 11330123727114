import React, { useEffect, useState } from 'react';
import UploadProduct from '../components/UploadProduct';
import SummaryApi from '../common';
import AdminProductCard from '../components/AdminProductCard';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import AdminEditProduct from '../components/AdminEditProduct';
import { useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import displayINRCurrency from '../helpers/displayCurrency';

const AllProducts = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [viewMode, setViewMode] = useState('icons'); // 'icons' or 'list'
  const [editProduct, setEditProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchAllProduct = async () => {
    try {
      const response = await fetch(SummaryApi.adminProduct.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_no_units_916',
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
  
      const dataResponse = await response.json();
  
      // Reverse the data
      const reversedProducts = (dataResponse?.data || []).reverse();
      setAllProduct(reversedProducts); // Set reversed data
    } catch (error) {
      console.error('Error fetching products:', error);
      // Optionally, you can use a notification or a toast to show the error
      toast.error(`Failed to fetch products: ${error.message}`);
    }
  };
  

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const handleEditProduct = (product) => {
    setEditProduct(product);
  };

  const handleSaveProduct = () => {
    fetchAllProduct(); // Refresh the product list
    setEditProduct(null); // Close the edit form
  };

  const filteredProducts = allProduct.filter(product =>
    product.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.product_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredProducts.map(product => ({
      'Product Code': product.product_code,
      'Created Date': new Date(product.created_date).toLocaleDateString('en-IN'),
      'Product Name': product.product_name,
      'Product Sub Title': product.sub_title,
      'Product Description': product.product_description,
      'Price': `₹ ${typeof product.price === 'number' ? product.price.toLocaleString('en-IN') : product.price}`,
      'Discount(%)': product.discount,
      'After Discount': `₹ ${typeof product.after_discount_price === 'number' ? product.after_discount_price.toLocaleString('en-IN') : product.after_discount_price}`,
      'Rating': product.rating,
      'Status': product.is_active === '1' ? 'Active' : 'Inactive',
      'Display': product.is_display === '1' ? 'True' : 'False',
      'Best Seller': product.best_seller === '1' ? 'True' : 'False',
      'Home Display': product.home_display === '1' ? 'True' : 'False',
    })), { header: ["Product Code", "Created Date", "Product Name", "Product Sub Title", "Product Description", "Price", "Discount(%)", "After Discount", "Rating", "Status", "Display", "Best Seller", "Home Display"] });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Products');
    XLSX.writeFile(wb, 'Products.xlsx');
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Product Code',
        accessor: 'product_code',
      },
      {
        Header: 'Created Date',
        accessor: 'created_date',
        Cell: ({ value }) => {
          return (
            <span className="text-gray-900 font-medium">
              {new Date(value).toLocaleDateString('en-IN')}
            </span>
          );
        },
      },
      {
        Header: 'Product Name',
        accessor: 'product_name',
      },
      {
        Header: 'Product Sub Title',
        accessor: 'sub_title',
      },
      {
        Header: 'Product Description',
        accessor: 'product_description',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {displayINRCurrency(value)}
          </span>
        ),
      },
      {
        Header: 'Discount(%)',
        accessor: 'discount',
      },
      {
        Header: 'After Discount',
        accessor: 'after_discount_price',
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {displayINRCurrency(value)}
          </span>
        ),
      },
      {
        Header: 'Rating',
        accessor: 'rating',
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      {
        Header: 'Display',
        accessor: 'is_display',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'True' : 'False'}
          </span>
        ),
      },
      {
        Header: 'Best Seller',
        accessor: 'best_seller',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'True' : 'False'}
          </span>
        ),
      },
      {
        Header: 'Home Display',
        accessor: 'home_display',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'True' : 'False'}
          </span>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <button
            onClick={() => handleEditProduct(row.original)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit
          </button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: allProduct,
  });

  return (
    <div>
      <div className='bg-white py-2 px-4 flex flex-col sm:flex-row justify-between items-center'>
        <h2 className='font-bold text-lg'>All Product</h2>

        <div className='flex'>
          <div className='flex items-center mx-2 text-sm'>
            <div>Active</div>
            <div className='mx-2 text-lg text-[#008000]'><FaCheckCircle /></div>
            <div className='ml-2'>Inactive</div>
            <div className='mx-2 text-lg text-[#D22B2B]'><FaTimesCircle /></div>
          </div>
          <button
            className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all py-1 px-3 rounded-full'
            onClick={() => setOpenUploadProduct(true)}
          >
            Upload Product
          </button>
          <button
            className='ml-2 border-2 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white transition-all py-1 px-3 rounded-full'
            onClick={() => {
              setViewMode(viewMode === 'icons' ? 'list' : 'icons');
              setSearchQuery(''); // Clear search query when switching view modes
            }}
          >
            {viewMode === 'icons' ? 'Switch to List' : 'Switch to Icons'}
          </button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mb-4 mt-4">
        <div className="flex-grow md:mr-2">
          <input
            type="text"
            placeholder="Search by name or code..."
            className="p-2 border border-gray-300 rounded w-full mb-2"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        <div>
          <button
            className="md:ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={() => setSearchQuery('')}
          >
            Clear
          </button>
          <button
            className="md:ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={downloadExcel}
          >
            Download Excel
          </button>
        </div>
      </div>



      {viewMode === 'icons' ? (
        <div className='flex items-center flex-wrap gap-5 py-4 overflow-y-scroll'>
          {filteredProducts.map((product, index) => (
            <AdminProductCard
              data={product}
              key={index + "allProduct"}
              fetchdata={fetchAllProduct}
              onEdit={() => handleEditProduct(product)}
            />
          ))}
        </div>
      ) : (
        <div className='overflow-x-auto p-4'>
          <table {...getTableProps()} className='w-full bg-white shadow-md rounded-lg'>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className='bg-gray-200'>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className='p-2 md:p-3 text-left'>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='border-b hover:bg-gray-100'>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} className='p-2 md:p-3 text-sm text-gray-800'>
                        {cell.render('Cell')}
                      </td>
                    ))}

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {openUploadProduct && (
        <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchData={fetchAllProduct} />
      )}

      {editProduct && (
        <AdminEditProduct
          productData={editProduct}
          onClose={() => setEditProduct(null)}
          fetchdata={fetchAllProduct}
        />
      )}
    </div>
  );
};

export default AllProducts;
